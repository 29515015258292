
/*
** preloader
*/

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 900;

	color: #000;
	background: #fff;
}

body .preloader.preloader--hide {

}

.preloader__content {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;

	transform: translate(-50%,-50%);
}

body .preloader.preloader--hide .preloader__content {

}

/* hidden */
body .preloader.preloader--hidden {
	display: none;
}

/* no js */
html.no-js .preloader {
	display: none;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.preloader__content .mark {
		transform: scale(1.4);
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}