/*
** swiper (overload : node_modules/swiper/css/swiper.css)
*/

/* color */
body .swiper .swiper-slide,
body .swiper .swiper-slide:after {
	background: #fff;
}
body .swiper .swiper-caption,
body .swiper .swiper-pagination {
	color: #000;
}

/* swiper */
body .swiper {
	position: relative;
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}

body .swiper .swiper-container {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
}

body .swiper .swiper-wrapper {

}

/* loading */
body .swiper .swiper-slide:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;

	content: '';
	background: #fff;
}

body .swiper .swiper-slide:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: 10px;
	z-index: 3;

	content: '';
	border-radius: 100%;
	background-color: #000;

	transform: translate(-50%,-50%);
	animation: loader-animation 1.0s infinite ease-in-out;
}

body .swiper .swiper-slide--loaded:before,
body .swiper .swiper-slide--loaded:after {
	opacity: 0;
}

/* image (max zone 1200px x 1200px)*/
body .swiper .swiper-image {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	z-index: 1;

	max-width: 1200px;
	max-height: 1200px;

	width : calc(100% - 120px);
	height: calc(100% - 140px);

	transform: translate(-50%,-50%);
}

body .swiper .swiper-image img {
	display: block;

	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	z-index: 1;

	object-fit: contain;
	object-position: 50% 50%;
}

body .swiper .swiper-slide.swiper-slide--img.loaded:before,
body .swiper .swiper-slide.swiper-slide--img.loaded:after {
	opacity: 0;
}

/* video */
body .swiper .swiper-slide--video video {
	display: block;
	cursor: pointer;

	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	z-index: 2;

	transform: translate(-50%,-50%);
}

body .swiper .swiper-slide--video img {
	opacity: 0;
}

body .swiper .swiper-slide.swiper-slide--video.loadeddata:before,
body .swiper .swiper-slide.swiper-slide--video.loadeddata:after {
	display: none;
}

/* overlay */
body .swiper .swiper-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;

	background: transparent;
}

/*
body .swiper .swiper-slide--video .swiper-overlay {
	display: none;
}
*/

/* caption */
body .swiper .swiper-caption {
	position: absolute;
	bottom: 2rem;
	left: 50%;
	width: 100%;
	z-index: 1;

	max-width: 50rem;
	padding-left: 2rem;
	padding-right: 2rem;
	transform: translate(-50%,0%);

	font-size: 0.8em;
	text-align: center;
}

body .swiper .swiper-button-play:hover {
	opacity: 0.5;
}

/* pagination */
body .swiper .swiper-pagination {

}

html body.body--scrolled .swiper .swiper-pagination {
	display: none;
}

/* buy */
body .swiper .swiper-buy {
	position: absolute;
	bottom: 2em;
	left: 5em;
	width: 2em;
	height: 2em;
	z-index: 10;

	transition: left 600ms;
}

body.body--scrolled .swiper .swiper-buy {
	left: 2em;
}

/* prev next */
body .swiper .swiper-button-prev,
body .swiper .swiper-button-next {
	width: 50px;
	height: 50px;
	margin-top: -25px;
	transition: opacity 300ms;
	opacity: 0.2;

	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}

body .swiper .swiper-button-prev {
	left: 0em;
	background-image: url(/assets/img/swiper/prev.svg);

}

body .swiper .swiper-button-next {
	right: 0em;
	background-image: url(/assets/img/swiper/next.svg);
}

body .swiper .swiper-button-prev:hover,
body .swiper .swiper-button-next:hover {
	opacity: 1;
}

body .swiper .swiper-button-prev:after,
body .swiper .swiper-button-next:after {
	display: none;
}

body .swiper .swiper-button-prev:focus,
body .swiper .swiper-button-next:focus {
	outline: none;
}

/* hidden-on-idle */
body.body--has-not-touch-event .swiper .hidden-on-idle {
	transition: opacity 600ms;
}

body.body--has-not-touch-event.body--idle .swiper .hidden-on-idle {
	opacity: 0;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	/* pagination */
	body .swiper .swiper-pagination {
		position: absolute;
		bottom: 2rem;
		left: 2rem;
		width: auto;
		z-index: 1;

		font-size: 0.7em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	/* pagination */
	body .swiper .swiper-pagination {
		display: none;
	}

	/* buy */
	body .swiper .swiper-buy {
		left: 1.5em;
	}
}

@media only screen and ( max-width: 640px ) {

	/* image */
	body .swiper .swiper-image {
		width : 100%;
		height: calc(100% - 200px);
	}

	/* button */
	body .swiper .swiper-button-prev,
	body .swiper .swiper-button-next {
		display: none;
	}
}
