
/*
** about
*/

body.body--about {

}

/* header */
body.body--about .header {
	background: #fff;
}

/* page */
.about .page__wrapper.wrapper {
	max-width: 65em;
	margin-left: auto;
	margin-right: auto;
}

.about .page__image {
	position: relative;
	margin-bottom: 2em;
}

.about .page__image:after {
	position: absolute;
	top: 0;
	left: -100vw;
	width: 200vw;
	height: 100%;
	z-index: -1;

	content: '';
	background: #000;
}

.about .page__text {
	color: #000;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.about .page__wrapper.wrapper {
		padding-top: 5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}