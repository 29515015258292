/*
** font face
*/

@font-face {
	font-family: 'Futura LT';
	src: url('/assets/font/FuturaLT-Bold.eot');
	src: url('/assets/font/FuturaLT-Bold.eot?#iefix') format('embedded-opentype'),
		url('/assets/font/FuturaLT-Bold.woff2') format('woff2'),
		url('/assets/font/FuturaLT-Bold.woff') format('woff'),
		url('/assets/font/FuturaLT-Bold.ttf') format('truetype'),
		url('/assets/font/FuturaLT-Bold.svg#FuturaLT-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Futura LT';
	src: url('/assets/font/FuturaLT-Book.eot');
	src: url('/assets/font/FuturaLT-Book.eot?#iefix') format('embedded-opentype'),
		url('/assets/font/FuturaLT-Book.woff2') format('woff2'),
		url('/assets/font/FuturaLT-Book.woff') format('woff'),
		url('/assets/font/FuturaLT-Book.ttf') format('truetype'),
		url('/assets/font/FuturaLT-Book.svg#FuturaLT-Book') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Futura LT';
	src: url('/assets/font/FuturaLT-Light.eot');
	src: url('/assets/font/FuturaLT-Light.eot?#iefix') format('embedded-opentype'),
		url('/assets/font/FuturaLT-Light.woff2') format('woff2'),
		url('/assets/font/FuturaLT-Light.woff') format('woff'),
		url('/assets/font/FuturaLT-Light.ttf') format('truetype'),
		url('/assets/font/FuturaLT-Light.svg#FuturaLT-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}