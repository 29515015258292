
/*
** work (image)
*/

body.body--work-image .header__logo {
	display: none;
}

.work--image {

}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.work--image .work__images {
		max-width: 70em;
		margin: auto;
	}

	.work--image .images__item {
		width: 33.3%;
		padding-left: 2em;
		padding-right: 2em;
		margin-bottom: 4em;
	}
}

@media only screen and ( min-width: 769px ) {

	.work--image .images__item {
		width: 20%;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.work--image .work__images {
		display: none;
	}
}