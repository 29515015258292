
/*
** wysiwyg--about
*/

.wysiwyg.wysiwyg--about {

}

/* h1, h2, h3, h4, h5, h6 */
.wysiwyg.wysiwyg--about  h1,
.wysiwyg.wysiwyg--about  h2,
.wysiwyg.wysiwyg--about  h3,
.wysiwyg.wysiwyg--about  h4,
.wysiwyg.wysiwyg--about  h5,
.wysiwyg.wysiwyg--about  h6 {
	margin-bottom: 2em;

	font-size: 0.65em;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.wysiwyg.wysiwyg--about > p {
		font-size: 1.7em;
		line-height: 1.5em;
	}

	.wysiwyg.wysiwyg--about div.contacts {
		display: flex;
		justify-content: center;
	}

	.wysiwyg.wysiwyg--about div.contacts p {
		width: 15em;

		padding-left: 2em;
		padding-right: 2em;
		margin-bottom: 2em;

		border-left: 1px solid currentColor;
	}

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.wysiwyg.wysiwyg--about div.contacts p {
		text-align: center;
		margin-bottom: 3em;
	}
}