
/*
** player
*/

.player {
	position: relative;
	width: 100%;
	height: 100vh;
}

.player__swiper {

}

.player__embed {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	z-index: 1;

	max-width: 1200px;
	max-height: 1200px;
	width : calc(100% - 50px);
	height: calc(100% - 200px);

	transform: translate(-50%,-50%);
}

.player__embed iframe {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;

	transform: translate(-50%,-50%);
}

.player__name {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 20;

	padding: 1.7rem;

	font-size: 0.9em;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.player__path {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 20;

	padding: 1.7rem;
}

/* scroll */
.player__scroll {
	position: absolute;
	bottom: 1rem;
	right: 0;
	z-index: 1;

	padding: 1rem;
	color: #000;
}

body.body--scrolled .player__scroll {
	display: none;
}

.player__scroll .button__label {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.player__name {
		left: 50%;
		transform: translateX(-50%);
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.player__name {
		max-width: 18rem;
	}

	.player__name span {
		display: block;
	}

	.player__path {
		display: none;
	}
}

@media only screen and ( max-width: 640px ) {

	.player__embed {
		width : 100%;
	}
}
