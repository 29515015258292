
/*
** image
*/

.image {

}

.image__vignette {
	position: relative;
}

.image__vignette video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	object-fit: cover;
}