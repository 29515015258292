
/*
** sections
*/

.sections {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sections__item {
	margin-bottom: 0.5em;
}

.sections__anchor {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	font-size: 1em;
	letter-spacing: 0.2em;
	color: inherit;
}

.sections__anchor:before {
	position: absolute;
	left: -0.5em;
	top: 50%;
	width: 0em;
	height: 2px;

	display: block;
	content: '';
	background: currentColor;
	transform: translate(-100%,-50%);
	transition: all 300ms ease;
}

.sections__anchor:after {
	position: absolute;
	right: -0.5em;
	top: 50%;
	width: 0em;
	height: 2px;

	display: block;
	content: '';
	background: currentColor;
	transform: translate(100%,-50%);
	transition: all 300ms ease;
}

.sections__anchor:hover:before,
.sections__anchor:hover:after,
.sections__anchor.selected:before,
.sections__anchor.selected:after {
	width: 0.5em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}