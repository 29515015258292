
/*
** page
*/

.page {

}

.page__wrapper {

}

.page__name {
	display: none;
}

.page__header {

}

.page__text {

}