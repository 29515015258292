
/*
** works
*/

.works {

}

.works__container {

}

.works__item {

}

.works__wrapper {
	position: relative;
}

.works__thumbnail {
	position: relative;
	overflow: hidden;
}

.works__thumbnail video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	object-fit: cover;

	/* no black line */
	width: calc(100% + 1px);
	height: calc(100% + 1px);
}

.works__info {

}

.works__heading {

}

.works__name,
.works__header {
	font-size: 0.8em;
	letter-spacing: 0.1em;
	line-height: 1.7em;
	font-weight: bold;
	text-transform: uppercase;
}

.works__header {

}

.works__buy {
	position: absolute;
	bottom: 0.5em;
	left: 0.5em;
	width: 1.8em;
	height: 1.8em;
	z-index: 1;
}

.works__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;

	background: transparent;
	opacity: 0;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.works__info {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		background: rgba(0, 0, 0, 0.5);
	}

	.works__heading {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		z-index: 1;

		padding: 2em;
		transform: translate(-50%,-50%);
		text-align: center;
	}

	.works__name {
		color: #fff;
	}

	/* hover */
	.works__info {
		transition: opacity 100ms;
		opacity: 0;
	}

	.works__item[data-video="1"]:hover .works__info {
		background: transparent;
	}

	.works__item:hover .works__info {
		opacity: 0.99;
	}

	.works__name,
	.works__header {
		opacity: 0;
		transition: opacity 100ms;
	}

	.works__item:hover .works__name,
	.works__item:hover .works__header {
		transition-delay: 100ms;
		opacity: 1;
	}

	.works__item:hover .works__header {
		transition-delay: 200ms;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.works__item {
		margin-bottom: 1.5em;
	}

	.works__info {
		position: relative;
		padding-top: 0.5em;
	}

	.works__name,
	.works__header {
		display: inline-block;
	}

	.works__name {
		color: #000;
	}

	.works__buy {
		width: 2em;
		height: 2em;

		transform: translateY(-100%);
	}
}
