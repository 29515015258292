
/*
** grid
*/

.grid {
	width: 100%;
}

.grid__container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;

	width: 100%;
	overflow: hidden;
}

.grid__item {
	position: relative;
}

.grid__item.left {

}

.grid__item.right {

}

.grid__separator {
	width: 100%;
	height: 1px;
}

.grid .img {
	display: block;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.grid .grid__item {
		width: 100% !important;
	}
}
