
/*
** underline
*/

.underline {
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.underline:after {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 0.15em;
	z-index: 1;

	content: '';
	background-color: currentColor;

	-webkit-transition: all 600ms;
	-o-transition: all 600ms;
	transition: all 600ms;
}

.underline:hover:after {
	width: 100%;
}