
/*
** langs
*/

.langs {
	margin: 0;
	padding: 0;
	list-style: none;

	display: none;
}

.langs__item {
	display: inline-block;
	vertical-align: middle;
}

.langs__item:not(:last-child):after {
	content : '/';
	font-size: 1em;
}

.langs__anchor {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	font-size: 1em;
	text-transform: uppercase;
}

.langs__anchor:hover {

}

.langs__anchor.selected {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}