
/*
** wysiwyg
*/

.wysiwyg {

}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6,
.wysiwyg p,
.wysiwyg li {
	margin: 0;
	padding: 0;
}

/* h1, h2, h3, h4, h5, h6 */
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
	margin-bottom: 2em;

	font-size: 1em;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

/* p */
.wysiwyg p {
	margin-bottom: 1em;

	font-size: 1em;
}

.wysiwyg p:last-child {
	margin-bottom: 0em;
}

/* a */
.wysiwyg a {
	text-decoration: underline;
}

.wysiwyg a:hover {
	text-decoration: none;
}

/* ul */
.wysiwyg ul {
	padding: 0;
	margin: 0;
	margin-bottom: 4em;

	list-style: none;
}

.wysiwyg li {
	margin-bottom: 1em;

	font-size: 1em;
	line-height: 1.6em;
}