
/*
** anchor
*/

.anchor, 
.anchor:hover {
	cursor: pointer;
	user-select: none;
	transition: all 600ms;
}