
/*
** works
*/

body.body--works {

}

/* works */
body.body--works .works {
	position: relative;

	filter: grayscale(100%);
	transition: filter 600ms;
}

body.body--works.body--page-start .works {
	filter: none;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}