
/*
** logo
*/

.logo {

}

.logo__anchor {
	color: inherit;
}

.logo__title {
	font-size: 1em;
	letter-spacing: 0.1em;
	white-space: nowrap;
	text-transform: uppercase;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}