
/*
** pagination
*/

.pagination {
	margin: 0;
	padding: 0;
	list-style: none;

	text-align: center;
	color: #000;
}

.pagination__item {
	display: inline-block;
	vertical-align: top;
}

.pagination__anchor {
	display: inline-block;
	vertical-align: top;

	margin: 0.5rem;

	font-size: 1em;
	opacity: 0.5;
}

.pagination__anchor:hover,
.pagination__anchor.selected {
	opacity: 1;
}

.pagination__item--prev,
.pagination__item--next {
	display: none;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.pagination {
		padding: 5em 2em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.pagination {
		padding: 1em;
	}
}