
/*
** work
*/

body.body--work .header__burger {
	display: none;
}

body.body--work .header__navigate {
	display: block;
}

.work span {
	color: #000;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}