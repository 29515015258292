
/*
** diapositive
*/

.diapositive {
	position: relative;
}

.diapositive:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	content: '';
	background: transparent;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) { 

	.diapositive {
		width: 100%;
		padding-bottom: 86%;
		overflow: hidden;
	}

	.diapositive .img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	/* objectfit */
	html.objectfit .diapositive .img {
		object-fit: contain;
	}

	html.objectfit .diapositive.diapositive--centered .img {
		object-position: 50% 50%;
	}

	html.objectfit .diapositive.diapositive--left-bottom .img {
		object-position: left bottom;
	}

	/* no objectfit */
	html.no-objectfit .diapositive .img.img--landscape {
		width: auto !important;
		height: 100%;
	}

	html.no-objectfit .diapositive .img.img--portrait {
		width: auto !important;
		height: 100%;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.diapositive .img {
		width: 100%;
		height: auto !important;
	}
}