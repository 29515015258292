
/*
** mark
*/

.mark, 
.mark__svg {
	width: 200px;
	height: 200px;
}

.mark {
	position: relative;
	overflow: hidden;
}

.mark__wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 3200px;
	z-index: 1;

	animation: mark 1s steps(1, end) infinite;
}

.mark__svg {
	display: block;
	float: left;
}

@keyframes mark {
	0%     { left:  0px;    }
	6.25%  { left: -200px;  }
	12.5%  { left: -400px;  }
	18.75% { left: -600px;  }
	25%    { left: -800px;  }
	31.25% { left: -1000px; }
	37.5%  { left: -1200px; }
	43.75% { left: -1400px; }
	50%    { left: -1600px; }
	56.25% { left: -1800px; }
	62.5%  { left: -2000px; }
	68.75% { left: -2200px; }
	75%    { left: -2400px; }
	81.25% { left: -2600px; }
	87.5%  { left: -2800px; }
	93.75% { left: -3000px; }
}