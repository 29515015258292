
/*
** wysiwyg--light
*/

.wysiwyg.wysiwyg--light {}

.wysiwyg.wysiwyg--light h1,
.wysiwyg.wysiwyg--light h2,
.wysiwyg.wysiwyg--light h3,
.wysiwyg.wysiwyg--light h4,
.wysiwyg.wysiwyg--light h5,
.wysiwyg.wysiwyg--light h6,
.wysiwyg.wysiwyg--light p {
	margin: 0;
	padding: 0;

	font-size: 1em;
	line-height: 1.2em;
}

.wysiwyg.wysiwyg--light ul,
.wysiwyg.wysiwyg--light blockquote {
	display: none;
}