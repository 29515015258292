
/*
** vignette
*/

.vignette {
	position: relative;
}

.vignette:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	content: '';
	background: transparent;
}

/* js */
html.js .vignette .img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

/* no js */
html.no-js .vignette .img {
	width: 100%;
	height: auto !important;
}