
/*
** work
*/

.work {

}

.work__player {

}


.work__poster {

}

.work__content {

}


.work__wrapper {

}

.work__text {
	max-width: 60em;
	margin: auto;

	color: #000;
	text-align: center;
}

.work__text {
	max-width: 60em;
	margin: auto;

	color: #000;
	text-align: center;
}

.work__credit {
	max-width: 30rem;
	margin: auto;

	color: #000;
	text-align: center;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.work__content .block:not(:last-child) {
		padding-bottom: 8rem;
	}

	/* images */
	.work .images__item {
		width: 50%;
	}
}


@media only screen and ( min-width: 769px ) {

	.work .images__item {
		width: 33.3%;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.work__content .block:not(:last-child) {
		padding-bottom: 4rem;
	}
}