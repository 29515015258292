
/*
** loader
*/

.loader {
	width: 40px;
	height: 40px;
	background-color: #000;

	border-radius: 100%;
	animation: loader-animation 1.0s infinite ease-in-out;
}

@keyframes loader-animation {
	0% { 
		transform: scale(0);
	} 100% {
		transform: scale(1.0);
		opacity: 0;
	}
}