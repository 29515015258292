
/*
** header (overload : flap, navigation)
*/

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;

	transition: all 600ms;
	text-transform: uppercase;
}

/* color */
.header__logo,
.header__navigate,
.header__burger,
.header .sections,
.header .socials {
	color: #000;
}

/* wrapper */
.header__wrapper {
	position: relative;
}

/* logo */
.header__logo {
	position: absolute;
	top: 1.8rem;
	left: 50%;
	z-index: 1;

	transform: translateX(-50%);

	font-size: 1.2em;
	text-align: center;
}

/* header */
.header__burger {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 101;
}

/* navigate */
.header__navigate {
	position: absolute;
	top: 0.8rem;
	right: 0;
	z-index: 101;

	display: none;
}

/* flap */
.header__flap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;

	overflow: hidden;
	background-color: #fefefe;

	transform: translateY(-100%);
	transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
}

body .header--open .header__flap {
	transform: translateY(0%);
}

/* section */
.header__section {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 1;

	font-size: 1em;
	line-height: 0.9em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;

	transform: translate(-50%, -50%);
}

body.body--page-start .header__section {
	animation-name: fadeout;
	animation-duration: 400ms;
	animation-timing-function: ease;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
}

@keyframes fadeout {
	0%   { opacity: 1;left: 50%;   }
	99%  { opacity: 0;left: 50%;   }
	100% { opacity: 0;left: -100%; }
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.header__section {
		font-size: 18vw;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.header__section {
		font-size: 28vw;
	}
}