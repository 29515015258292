
/*
** wrapper
*/

.wrapper {
	padding: 5em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.wrapper {
		padding-left: 2em;
		padding-right: 2em;
	}
}

@media only screen and ( min-width: 1280px ) {

	.wrapper {
		padding-left: 5em;
		padding-right: 5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.wrapper {
		padding-left: 1em;
		padding-right: 1em;
	}
}