
/*
** burger
*/

.burger {
	position: relative;
	cursor: pointer;

	width: 5em;
	height: 5em;
}

.burger__shape {
	display: block;

	position: absolute;
	top: 50%;
	left: 50%;
	width: 2em;
	height: 1px;

	background-color: transparent;
	transform: translate(-50%, -50%);
	transition: background 0.3s;
}

.burger__shape::before,
.burger__shape::after {
	content: '';
	display: block;

	position: absolute;
	top: 0;
	left: 0;
	width: 2em;
	height: 1px;

	background-color: currentColor;
	transform: translateY(-0.4em);
	transition: transform 0.3s, background 0.3s;
}

.burger__shape::after {
	transform: translateY(0.4em);
}

/* hover */
.burger:hover .burger__shape::before {
	transform: translateY(-0.6em);
}

.burger:hover .burger__shape::after {
	transform: translateY(0.6em);
}

/* open */
.header.header--open .burger__shape {
	background: transparent;
}

.header.header--open .burger__shape::before {
	transform: translateY(0) rotate(45deg);
}

.header.header--open .burger__shape::after {
	transform: translateY(0) rotate(-45deg);
}
