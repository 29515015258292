
/*
** top
*/

.top {

}

.top.top--hidden {
	opacity: 0;
	pointer-events: none;
}

.top__button {
	padding: 1em;
}

.top__button:hover {
	transform: translateY(-20%);
}

.top .button__svg {

}

.top .button__label {
	display: none;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}

