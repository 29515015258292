
/*
** path
*/

.path {

}

.path .anchor,
.path .anchor:after {
	font-size: 0.9em;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.path .anchor:hover {
	opacity: 0.5;
}

.path .anchor:not(:last-child):after {
	content: ', ';
}