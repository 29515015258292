
/*
** films
*/

.films {

}

.films__container {

}

.films__item {

}

.films__wrapper {
	position: relative;
}

.films__embed {

}

.films__info {

}

.films__name {
	font-size: 0.8em;
	line-height: 1.7em;
	font-weight: bold;
	text-transform: uppercase;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.films {
		overflow: hidden;
	}

	.films__container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		position: relative;
		left: -1em;
		width: calc(100% + 2em);
	}

	.films__item {
		width: 50%;
		padding-left: 1em;
		padding-right: 1em;
		margin-bottom: 3em;
	}

	.films__info {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		padding: 1em;

		background: rgba(0,0,0,0.6);
		transition: opacity 600ms;
	}

	.films__item.films__item--play .films__info {
		display: none;
	}

	.films__name {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		z-index: 1;

		transform: translate(-50%,-50%);
		text-align: center;
		color: #fff;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.films__item {
		margin-bottom: 2em;
	}

	.films__info {
		padding-top: 0.5em;
	}
}