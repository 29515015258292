
/*
** poster
*/

.poster {
	position: relative;
	background: #000;
}

.poster:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	content: '';
	background: linear-gradient(to top, rgba(0,0,0,0.4864320728291317) 0%, rgba(255,255,255,0) 100%);
	opacity: 1;
}

.poster .image__vignette {
	display: block;
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.poster .image__vignette .img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	object-position: 50% 50%;
	object-fit: cover;
}

.poster__name {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;

	transform: translate(-50%,-50%);

	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
}

.poster__path {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 20;

	padding: 1.8rem;
}


.poster__name span {
	color: #fff !important;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.poster__name {
		font-size: 7em;
		line-height: 1em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.poster__name {
		font-size: 3.5em;
		line-height: 1em;
	}

	.poster__path {
		display: none;
	}
}
