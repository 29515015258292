
/*
** socials
*/

.socials {
	margin: 0;
	padding: 0;
	list-style: none;
}

.socials__item {
	margin-bottom: 0.5em;
}

.socials__anchor {
	font-size: 1em;
	letter-spacing: 0.2em;
	color: inherit;
}

.socials__anchor:hover {
	opacity: 0.5;
}

/* button */
.socials .button__svg {
	width: 1em;
	height: 1em;
}

.socials .button__label {

}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) { 

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) { 

}