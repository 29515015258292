
/*
** work (film)
*/

body.body--work-film .header__logo {
	display: none;
}

.work--film {

}