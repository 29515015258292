
/*
** html
*/

html {
	font-size: 100%;
	height: 100%;
}

body {
	font-family: 'Futura LT', sans-serif;
	font-size: 1em;
	font-weight: normal;
	letter-spacing: 0em;

	color: #000;
	background: #fff;

	overflow-x: hidden;
	overflow-y: scroll;
}

*, *:before, *:after {
	box-sizing: border-box;
}

/*
** selection
*/

::-moz-selection {

}

::selection {

}

::-moz-selection {

}

/*
** anchor
*/

a { 
	color: currentColor;
	text-decoration: none;
}

a:focus { 
	outline: none;
}

/*
** figure
*/

figure { 
	margin: 0;
	line-height: 0;
	color: transparent;
}

/*
** h1, h2, h3, h4, h5, h6
*/

h1, h2, h3, h4, h5, h6 { 
	margin: 0;
	padding: 0;

	font-size: 1em;
	line-height: 1.2em;
	letter-spacing: 0.05em;
	font-weight: normal;

}

/*
** 320 x 480
** 360 x 640
** 768 x 1024
** 800 x 1280
** 1280 x 600
** 1920 x 900
*/


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}
