
/*
** wysiwyg--work
*/

.wysiwyg.wysiwyg--work {

}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.wysiwyg.wysiwyg--work p {
		font-size: 1.7em;
		line-height: 1.7em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}