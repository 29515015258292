
/*
** embed
*/

.embed iframe {
	background: #000;
}

.embed p {
	display: none;
}