
/*
** references
*/

.references {

}

.references__container {
	position: relative;
}

.references__item {
	cursor: pointer;
	margin-bottom: 0.5em;

	display: inline-block;
	vertical-align: top;
}

.references__name {
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.references__item:not(:last-child) .references__name:after {
	content: ', ';
	margin-right: 0.2em;
	font-size: 1em;
}

.references__image {
	position: absolute;
	top: 3em;
	left: 0;
	z-index: 100;

	display: none;
}

.references__image--landscape { width: 20em; }
.references__image--portrait  { width: 15em; }

.references__item:hover .references__image {
	display: block;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.references__container {
		padding-right: 5%;
	}

	.references__name {
		font-size: 1.7em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.references__name {
		font-size: 1.2em;
	}

	.references__image {
		display: none;
	}
}
