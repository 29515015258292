
/*
** buy
*/

.buy {
	position: relative;
}

.buy__svg {
	position: absolute;
	top: 0;
	left : 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	transition: transform 100ms ease-out;
}

.buy__svg--1 {

}

.buy--hover .buy__svg--2 {
	transform: translate(0.1em, -0.4em);
}

.buy--hover:hover .buy__svg--2 {
	transform: translate(0em, 0em);
}