
/*
** prev
*/

.prev {

}

.prev__anchor {
	padding: 10px;
	color: inherit;
}

.prev__anchor:hover {
	opacity: 0.5;
}

.prev .button__label {
	display: none;
}

.prev .button__svg {
	width: 30px;
	height: 30px;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}