
/*
** flap
*/

.flap {
	position: relative;
}

.flap__wrapper {

}

/* langs */
.flap__langs {

}

/* navigation */
.flap__navigation {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;

	transform: translate(-50%,-50%);
	opacity: 0;
}

body .header--open .flap__navigation {
	opacity: 1;
	transition: all 600ms;
	transition-delay: 600ms;
}


/* socials */
.flap__socials {
	position: absolute;
	bottom: 0%;
	left: 50%;
	z-index: 2;

	transform: translateX(-50%);
	opacity: 0;
}

body .header--open .flap__socials {
	opacity: 1;
	transition: all 600ms;
	transition-delay: 600ms;
}

.flap__socials .socials {
	padding: 2rem;

	font-size: 1.4em;
}

.flap__socials .socials__item {
	display: inline;
}

.flap__socials .socials__item:not(:last-child) {
	margin-right: 0.5em;
}

.flap__socials .button__label {
	display: none;
}

/* overlay */
.flap__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	background: transparent;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}