
/*
** introduction
*/

.introduction {
	height: 100vh;
	color: #000;
}

.introduction__wrapper {

}

/* logo */
.introduction__logo {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 1;

	transform: translate(-50%, -50%);
}

.introduction__logo .logo__title {
	position: relative;
	display: inline-block;
	overflow: hidden;

	font-weight: bold;
}

.introduction__logo .logo__title span {
	display: inline-block;
	transform: translateY(100%);
}

body.body--home-enter .introduction__logo .logo__title span {
	transition: transform 800ms ease-out;
	transform: translateY(0%);
}

/* image */
.introduction__image {
	position: relative;
	z-index: -1;
}
.introduction__image .image__vignette.vignette {
	padding: 0em !important;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.introduction__image .image__vignette .img,
.introduction__image .image__vignette video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	object-fit: contain;
}

/* overlay */
.introduction__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	background: transparent;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.introduction__logo {
		font-size: 7vw;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.introduction__logo {
		font-size: 7vw;
	}
}
