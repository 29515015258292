
/*
** work (folder)
*/

body.body--work-folder:not(.body--xscrolled) .header__logo,
body.body--work-folder:not(.body--xscrolled) .header__navigate {
	color: #fff;
}

body.body--work-folder.body--xscrolled .header {
	background: #fff;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}


/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	body.body--work-folder:not(.body--xscrolled) .header__logo {
		display: none;
	}
}