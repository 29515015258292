
/*
** home
*/

body.body--home .header__logo {
	display: none;
}

.home {

}

/* introduction */
.home__introduction {
	position: relative;
	color: #000;
}

.home__introduction:before {
	position: absolute;
	bottom: 5em;
	left: 50%;
	z-index: 1;

	content : 'Selection';
	transform: translate(-50%, -100%);
	text-transform: uppercase;

	font-size: 0.65em;
	letter-spacing: 0.2em;
}

.home__introduction:after {
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 1px;
	height: 3em;
	z-index: 1;

	content : '';
	transform: translate(-50%, 0%);
	background: currentColor;
}

.home__introduction:before,
.home__introduction:after {
	opacity: 0;
}

body.body--home-enter .home__introduction:before,
body.body--home-enter .home__introduction:after {
	transition: all 600ms;
	transition-delay: 800ms;
	opacity: 1;
}

.home__sections {
	color: #000;
	text-transform: uppercase;
	text-align: center;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}