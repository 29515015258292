
/* normalize */
@import url("./normalize.css");

/* main */
@import url("./font.css");
@import url("./main.css");

/* class */
@import url("./class/anchor.css");
@import url("./class/browserupgrade.css");
@import url("./class/burger.css");
@import url("./class/button.css");
@import url("./class/close.css");
@import url("./class/diapositive.css");
@import url("./class/embed.css");
@import url("./class/lazyload.css");
@import url("./class/loader.css");
@import url("./class/nprogress.css");
@import url("./class/preloader.css");
@import url("./class/underline.css");
@import url("./class/vignette.css");

/* block */
@import url("./block/buy.css");
@import url("./block/films.css");
@import url("./block/grid.css");
@import url("./block/introduction.css");
@import url("./block/mark.css");
@import url("./block/page.css");
@import url("./block/path.css");
@import url("./block/player.css");
@import url("./block/poster.css");
@import url("./block/references.css");
@import url("./block/swiper.css");
@import url("./block/works.css");

/* block : image */
@import url("./block/image/image.css");
@import url("./block/image/images.css");

/* block : work */
@import url("./block/work/work.css");
@import url("./block/work/work_file.css");
@import url("./block/work/work_film.css");
@import url("./block/work/work_folder.css");
@import url("./block/work/work_image.css");

/* block : wysiwyg */
@import url("./block/wysiwyg/wysiwyg.css");
@import url("./block/wysiwyg/wysiwyg--about.css");
@import url("./block/wysiwyg/wysiwyg--credit.css");
@import url("./block/wysiwyg/wysiwyg--light.css");
@import url("./block/wysiwyg/wysiwyg--work.css");

/* navigation */
@import url("./navigation/back.css");
@import url("./navigation/flap.css");
@import url("./navigation/langs.css");
@import url("./navigation/logo.css");
@import url("./navigation/navigate.css");
@import url("./navigation/navigation.css");
@import url("./navigation/next.css");
@import url("./navigation/pagination.css");
@import url("./navigation/prev.css");
@import url("./navigation/sections.css");
@import url("./navigation/socials.css");
@import url("./navigation/top.css");

/* layout */
@import url("./layout/footer.css");
@import url("./layout/header.css");
@import url("./layout/main.css");
@import url("./layout/section.css");
@import url("./layout/wrapper.css");

/* section */
@import url("./section/about.css");
@import url("./section/home.css");
@import url("./section/work.css");
@import url("./section/works.css");

/* dependencies */
@import url("../../node_modules/swiper/swiper-bundle.css");

