
/*
** images
*/

.images {

}

.images__container {

}

.images__item {

}

.images__thumbnail {

}

.images__caption {
	display: none;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.images {
		overflow: hidden;
	}

	.images__container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		position: relative;
		left: -0.5em;
		width: calc(100% + 1em);
	}

	.images__item {
		width: 33.3%;
		padding-left: 0.5em;
		padding-right: 0.5em;
		margin-bottom: 1em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.images__item {
		margin-bottom: 1em;
	}
}