
/*
** navigation
*/

.navigation {
	text-align: center;
}

.navigation__item {
	margin-bottom: 3em;
}

.navigation__wrapper {

}

.navigation__name {
	margin-bottom: 1rem;

	font-size: 1em;
	letter-spacing: 0.1em;
	font-weight: bold;
}

.navigation__content {

}

.navigation .sections {

}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.navigation__name {
		font-size: 3em;
	}

	.navigation__content {
		font-size: 1.5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.navigation__name {
		font-size: 3em;
	}

	.navigation__content {
		font-size: 1.2em;
	}
}