
/*
** navigate
*/

.navigate {
	display: flex;
}

.navigate__back,
.navigate__prev,
.navigate__next {
	width: 50px;
	height: 50px;
}

.navigate__prev,
.navigate__next {
	display: none;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

}