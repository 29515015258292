
/*
** footer
*/

body.body--transition .footer {
	opacity: 0;
	pointer-events: none;
}

.footer {
	text-align: center;
	text-transform: uppercase;
	color: #000;
}

.footer__wrapper.wrapper {
	padding-top: 1.5em;
	padding-bottom: 1.5em;
}

.footer__wrapper:before {
	margin: auto;
	width: 1px;
	height: 100px;
	background: currentColor;

	display: block;
	content: '';
}

/* color */
.footer {
	color: #000;
}

/* info */
.footer__info {

}

.footer__section {
	margin-bottom: 0.5em;

	font-size: 1em;
	font-weight: bold;
}

.footer__logo {
	font-size: 1em;
}

/* navigation */
.footer__navigation {
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer__sections,
.footer__socials {
	width: 50%;
	padding: 1rem;

	font-size: 0.65em;
}

.footer__sections {
	text-align: right;
}

.footer__socials {
	text-align: left;
}

.footer__socials .button__svg {
	display: none;
}

/* copyright */
.footer__copyright {
	padding: 2rem;

	font-size: 0.6em;
	letter-spacing: 0.2em;
}

/* top */
.footer__top {

}


/*
** responsive desktop
*/

@media only screen and ( min-width: 769px ) {

	.footer__info,
	.footer__navigation {
		padding: 1em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 768px ) {

	.footer__info,
	.footer__navigation {
		padding: 1em;
	}
}